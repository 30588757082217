import { Button } from 'components/Phantom/Button'
import { CSSProperties, FC, useRef, useState } from 'react'
import { BarProps, GraphProps, PersonalSleepProps } from './types'
import styles from './PersonalSleep.module.scss'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import { getGraphData, getNumColumns, getNumRows } from 'components/Phantom/_sections/PersonalSleep/utils'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TempDialAnimateUp } from 'components/Phantom/TempDial'
import { VideoGif } from 'components/basic/VideoGif'
import { useRefreshScrollTrigger } from 'components/_hooks/useRefreshScrollTrigger'
import { amClickedButton } from 'events/amplitude'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const PersonalSleepConnected = observer(({ ctaUrl, anchorHref }: { ctaUrl?: string; anchorHref?: string }) => {
	const { settingsStore } = useRootStore()
	return (
		<PersonalSleep
			ctaUrl={ctaUrl}
			metric={settingsStore.metricRegion}
			anchorHref={anchorHref}
		/>
	)
})

export const PersonalSleep: FC<PersonalSleepProps> = (props) => {
	const { metric = false, ctaUrl = '/product/pod-cover', anchorHref } = props

	const containerRef = useRef(null)

	useRefreshScrollTrigger(containerRef)

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<header className={styles.header}>
				<h2>We make sleep personal</h2>
				<p>The Pod personalizes your sleep setup with Autopilot to create the ultimate sleeping environment for deeper and higher quality sleep.</p>
				<Button.Primary
					id={'personal_sleep_cta'}
					className={styles.header_cta}
					href="/pod-cover"
				>
					See how it works
				</Button.Primary>
			</header>
			<article className={styles.perfect_temperature}>
				<div className={styles.main_image_container}>
					<VideoGif
						src={'https://d115sb6i1ixllw.cloudfront.net/assets/sleep_personal_hi_res.mp4'}
						className={styles.main_image}
					/>
					<TempDialAnimateUp
						endTemp={65}
						metric={metric}
						text={'Their side'}
						className={styles.cool}
					/>
					<TempDialAnimateUp
						endTemp={78}
						metric={metric}
						text={'Your side'}
						className={styles.warm}
					/>
				</div>
				<ul className={styles.benefit_container}>
					<li>
						<em style={{ visibility: 'hidden' }}>New</em>
						<Icon
							name={'TemperatureLight'}
							color={'white'}
							size={48}
						/>
						<h3>The perfect temperature all night</h3>
						<p>As your body temperature changes overnight, Autopilot heats and cools your Pod to match your body’s natural rhythm, keeping you in deep and REM sleep for longer. </p>
					</li>
					<li>
						<em>New</em>
						<Icon
							name={'TrendingUpLight'}
							color={'white'}
							size={'48px'}
						/>
						<h3>The ideal elevation for better sleep</h3>
						<p>Whether your body needs improved breathing, pressure relief or more comfort in bed, Autopilot elevates your Pod 4 Ultra to adjust your position for higher quality sleep. </p>
					</li>
				</ul>
			</article>

			<ExtraSleep
				ctaUrl={ctaUrl}
				anchorHref={anchorHref}
			/>
		</section>
	)
}

export const ExtraSleep = ({ ctaUrl, anchorHref }: { ctaUrl?: string; anchorHref?: string }) => {
	const cta = anchorHref ? (
		<AnchorLink
			offset={50}
			href={anchorHref}
		>
			<Button.White
				id={'personal-sleep-cta'}
				onClick={() => {
					amClickedButton('Personal sleep CTA', 'personal-sleep-cta')
				}}
			>
				Shop now
			</Button.White>
		</AnchorLink>
	) : (
		<Button.White
			id={'personal-sleep-cta'}
			href={ctaUrl}
		>
			Shop now
		</Button.White>
	)

	return (
		<article className={styles.extra_sleep}>
			<div className={styles.extra_sleep_inner}>
				<header className={styles.science_header}>
					<div>
						<p className={styles.eyebrow}>The science</p>
						<h3>The Pod gives you up to one more hour of sleep every night</h3>
						{cta}
					</div>
				</header>

				<Graph data={getGraphData()} />
			</div>
		</article>
	)
}

const Graph: FC<GraphProps> = (props) => {
	const [highlighted, setHighlighted] = useState<number | null>(0)

	const highlightedBar = props.data[highlighted]

	const numColumns = getNumColumns(props.data)
	const numRows = getNumRows(props.data)
	return (
		<div>
			<p className={styles.graph_header}>That's up to 15 days of extra sleep per year</p>

			<div className={styles.graph_grid}>
				{props.data.map((bar) => (
					<Bar
						key={bar.label}
						label={bar.label}
						row={bar.row}
						endColumn={bar.endColumn}
						benefit={bar.benefit}
						highlighted={bar === highlightedBar}
					/>
				))}

				<GridBorders
					numColumns={numColumns}
					numRows={numRows}
				/>

				<div
					className={styles.graph_buttons}
					style={{
						gridColumnStart: 2,
						gridColumnEnd: numColumns + 1,
						gridRow: numRows + 1,
					}}
				>
					{props.data.map((bar, index) => (
						<Button.Empty
							id={`graph_button_${bar.buttonLabel}`}
							key={bar.buttonLabel}
							className={cx(styles.graph_button, { [styles.graph_button_highlighted]: index === highlighted })}
							onClick={() => setHighlighted(index)}
						>
							{bar.buttonLabel}
						</Button.Empty>
					))}
				</div>

				{props.data.map((bar, index) => (
					<div
						className={styles.graph_button_mobile}
						style={{
							gridColumnStart: 2,
							gridColumnEnd: numColumns + 1,
							gridRow: index + 1,
						}}
						key={`graph-wrapper-${index}`}
					>
						<Button.Empty
							id={`graph_button_mobile_${bar.buttonLabel}`}
							key={bar.buttonLabel}
							className={cx(styles.graph_button, { [styles.graph_button_highlighted]: index === highlighted })}
							onClick={() => setHighlighted(index)}
						>
							{bar.buttonLabel}
						</Button.Empty>
					</div>
				))}

				<p
					className={styles.disclaimer}
					style={{
						gridColumnStart: 2,
						gridColumnEnd: numColumns + 1,
						gridRow: numRows + 2,
					}}
				>
					* Individual results may vary, and the extent of improvement in sleep cannot be guaranteed. Factors such as lifestyle, pre-existing medical conditions, and adherence to product usage
					instructions can influence outcomes.
				</p>
			</div>
		</div>
	)
}

const GridBorders = ({ numRows = 3, numColumns = 5 }) => {
	return (
		<>
			{Array.from({ length: numColumns }).map((_, index) => (
				<div
					className={styles.grid_border_right}
					style={{
						gridColumn: index + 1,
						gridRow: `1 / ${numRows + 1}`,
					}}
					key={`graph-borddr-${index}`}
				/>
			))}
			<div
				className={styles.grid_border_bottom}
				style={{
					gridColumn: `2 / ${numColumns + 1}`,
					gridRow: numRows,
				}}
			/>
			<div
				className={styles.grid_border_mobile_left}
				style={{
					gridColumn: 1,
					gridRow: `1 / ${numRows + 1}`,
				}}
			></div>
		</>
	)
}

const Bar: FC<Partial<BarProps>> = (props) => {
	return (
		<>
			<p
				className={styles.time}
				style={{
					gridColumn: '1',
					gridRow: `${props.row}`,
				}}
			>
				{props.label}
			</p>
			<div
				className={cx(styles.bar, { [styles.bar_highlighted]: props.highlighted })}
				style={{
					gridRow: `${props.row}`,
					gridColumn: `2 / ${props.endColumn + 2}`,
				}}
			/>
			<p
				className={styles.benefit}
				style={
					{
						gridRow: `${props.row}`,
						'--end-col-desktop': props.endColumn + 2,
						'--end-col-mobile': 4,
						opacity: props.highlighted ? 1 : 0.25,
					} as CSSProperties
				}
			>
				+ {props.benefit}
			</p>
		</>
	)
}
