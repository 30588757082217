import { BenefitProps } from 'components/_utils/utilityTypes'

export const getDefaultBenefits = (): BenefitProps[] => {
	return [
		{
			icon: 'CoolLight',
			text: 'Instant and all night cooling',
		},
		{
			icon: 'MattressLight',
			text: 'Maintains your mattress’ comfort',
		},
		{
			icon: 'SnoringLight',
			text: 'Detects and tracks snoring',
		},
		{
			icon: 'SnoreMitigationCustom',
			text: 'Reduces snoring automatically',
		},
		{
			icon: 'ElevationDark',
			text: 'Elevates to reduce pressure',
		},
		{
			icon: 'TapCustom',
			text: 'Control it without a phone',
		},
	]
}
